'use client';

import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import * as Sentry from '@sentry/nextjs';
import { getSessionId } from '@debt-relief/session-id';
import { canUseDom, gtag } from '@debt-relief/utils';
import { trackEvent } from '@shared/analytics';

/**
 * Track visit only on client side, on server side fallback into Promise stub
 * Don't use React hooks here to reduce waiting time
 */
const trackVisit = async (): Promise<void> => {
  if (!canUseDom()) {
    return;
  }

  try {
    gtag({ event: 'lead_visit' });

    await trackEvent({ type: 'visit', payload: {} }, { instant: true });
  } catch (error) {
    Sentry.captureException(
      new Error(`Session init failed with error: ${(error as Error).message}`),
    );
  }
};

const initPromise = trackVisit();

export interface TSessionIdContextValue {
  sessionId: string;
  initialized: boolean;
}

const missingSessionIdProvider =
  'You forgot to wrap your app in <SessionIdProvider>';

const invariantSessionIdProvider = (): never => {
  throw new Error(missingSessionIdProvider);
};

export const SessionIdContext = createContext<TSessionIdContextValue>({
  get sessionId() {
    return invariantSessionIdProvider();
  },
  get initialized() {
    return invariantSessionIdProvider();
  },
});

export const SessionIdProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    void initPromise.then(() => {
      setInitialized(true);
    });
  }, []);

  const value = useMemo<TSessionIdContextValue>(
    () => ({ sessionId: canUseDom() ? getSessionId() : '', initialized }),
    [initialized],
  );

  return (
    <SessionIdContext.Provider value={value}>
      {children}
    </SessionIdContext.Provider>
  );
};

export const useSessionId = (): TSessionIdContextValue =>
  useContext(SessionIdContext);
