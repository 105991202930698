import(/* webpackMode: "eager" */ "/app/apps/app/src/entities/inactivity-modal/lib/use-inactivity-modal-state.ts");
import(/* webpackMode: "eager" */ "/app/apps/app/src/entities/inactivity-modal/ui/inactivity-modal-provider.tsx");
import(/* webpackMode: "eager" */ "/app/apps/app/src/shared/analytics/events-queue.ts");
import(/* webpackMode: "eager" */ "/app/apps/app/src/shared/analytics/track-event.ts");
import(/* webpackMode: "eager" */ "/app/apps/app/src/shared/analytics/use-analytics.ts");
import(/* webpackMode: "eager" */ "/app/apps/app/src/shared/components/app-providers/index.tsx");
import(/* webpackMode: "eager" */ "/app/apps/app/src/shared/components/styled-components-registry/index.tsx");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"../../packages/common-ui/src/theme/fonts.ts\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"fallback\":[\"system-ui\",\"arial\"],\"adjustFontFallback\":false,\"display\":\"swap\"}],\"variableName\":\"interFont\"}");
import(/* webpackMode: "eager" */ "/app/packages/common-ui/src/button/styles.ts");
import(/* webpackMode: "eager" */ "/app/packages/common-ui/src/global-styles/styles.ts");
import(/* webpackMode: "eager" */ "/app/packages/common-ui/src/icons/close-icon/styles.ts");
import(/* webpackMode: "eager" */ "/app/packages/common-ui/src/icons/icon/styles.ts");
import(/* webpackMode: "eager" */ "/app/packages/common-ui/src/section/styles.ts");
import(/* webpackMode: "eager" */ "/app/packages/common-ui/src/text-highlight/styles.ts");
import(/* webpackMode: "eager" */ "/app/packages/common-ui/src/theme-provider/theme-provider.tsx")