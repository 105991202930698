'use client';

import { useRef, useState } from 'react';
import { useServerInsertedHTML } from 'next/navigation';
import { ServerStyleSheet, StyleSheetManager } from 'styled-components';
import { canUseDom } from '@debt-relief/utils';

export const StyledComponentsRegistry: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [styledComponentsStyleSheet] = useState(() => new ServerStyleSheet());

  const isServerInsertedRef = useRef(false);

  useServerInsertedHTML(() => {
    if (!isServerInsertedRef.current) {
      isServerInsertedRef.current = true;
      const styles = styledComponentsStyleSheet.getStyleElement();
      styledComponentsStyleSheet.instance.clearTag();
      return <>{styles}</>;
    }
  });

  if (canUseDom()) {
    return <>{children}</>;
  }

  return (
    <StyleSheetManager sheet={styledComponentsStyleSheet.instance}>
      {children}
    </StyleSheetManager>
  );
};
